<template>
    <v-dialog width="450" v-model="dialog">
        <v-card :disabled="loading">
            <ValidationObserver ref="form">
                <v-form @submit.prevent="handleSubmit">
                    <v-card-title>
                        {{ $capitalize($tc("model.damages_coverage")) }}
                        <v-spacer></v-spacer>
                        <v-btn @click="handleCancel" icon>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        <ValidationProvider
                            name="capital"
                            rules="required|min_value:1"
                            v-slot="{ errors }"
                        >
                            <MoneyInput
                                v-model="capital"
                                name="capital"
                                :label= "__('Capital', 'leads')"
                                autofocus
                                @keyup.enter="handleSubmit"
                                :error-messages="errors"
                            ></MoneyInput>
                        </ValidationProvider>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="handleCancel" color="primary" text>
                            {{ $capitalize($tc("model.damages_cancel_button")) }}
                        </v-btn>
                        <v-btn color="primary" type="submit" :loading="loading">
                            {{ $capitalize($tc("model.damages_save_button")) }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
import MoneyInput from "@/components/MoneyInput";
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    components: { MoneyInput }, 
    
    props: {
        loading: Boolean
    },
    
    data() {
        return {
            capital: 0,
            item: null,
            dialog: false
        }
    },

    methods: {
        handleCancel() {
            this.dialog = false
            this.$emit('cancel')
        },
        async handleSubmit() {
            if(!this.$refs.form.validate()) return;

            this.$emit('submit', {
                capital: this.capital,
                item: this.item
            })
        },
        init(item) {
            this.capital = item.capital
            this.item = item
            this.dialog = true;

            if(this.$refs.form) this.$refs.form.reset();
        },
        close() {
            this.dialog = false;
        }
    }
}
</script>