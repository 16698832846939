<template>
    <v-row>
        <v-col>
            <v-card v-if="lead" outlined>
                <v-card-title>
                    {{ $capitalize($tc('model.infrainsurance_infrainsurance_title')) }}
                </v-card-title>

                <v-alert v-if="insured_object == null" type="warning" text class="ma-3">
                    {{ $capitalize($tc('model.infrainsurance_undefined_insurance_message')) }}
                </v-alert>

                <v-list>
                    <v-list-item @click="handleEditScrin" link>
                        <v-list-item-content>
                            <v-list-item-subtitle>
                                {{ $capitalize($tc('model.infrainsurance_scrim_method')) }}
                            </v-list-item-subtitle>
                            <v-list-item-title>
                                {{ infrainsurance_scrin_total }}
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="handleEditTradicional" link>
                        <v-list-item-content>
                            <v-list-item-subtitle>
                                {{ $capitalize($tc('model.infrainsurance_traditional_method')) }}
                            </v-list-item-subtitle>
                            <v-list-item-title>
                                {{ infrainsurance_tradicional_total }}
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <v-card-text>
                    <v-radio-group :value="send_to_company" @change="updateSendToCompany" :label="$capitalize($tc('model.infrainsurance_send_company'))" horizontal>
                        <v-radio :value="0" :label="$capitalize($tc('model.infrainsurance_none_method'))"></v-radio>
                        <v-radio value="scrin" :label="$capitalize($tc('model.infrainsurance_scrim_method')) + `(${infrainsurance_scrin_insured_capital})`"></v-radio>
                        <v-radio value="tradicional" :label="$capitalize($tc('model.infrainsurance_traditional_method')) + `(${infrainsurance_tradicional_insured_capital})`"></v-radio>
                    </v-radio-group>
                </v-card-text>
            </v-card>

            <money-dialog 
                ref="scrin_dialog"
                @submit="updateScrin"
                @cancel="handleCancelScrin"
                :title="$capitalize($tc('model.infrainsurance_update_scrim'))"
                rules=""
                :loading="loading_scrin"
            ></money-dialog>

            <money-dialog
                ref="tradicional_dialog"
                @submit="updateTradicional"
                @cancel="handleCancelTradicional"
                :title="$capitalize($tc('model.infrainsurance_update_traditional'))"
                rules=""
                :loading="loading_tradicional"
            ></money-dialog>
        </v-col>
    </v-row>
</template>

<script>
import Money from "@/tools/money"
import MoneyDialog from "@/components/MoneyDialog";

export default {
    components: {
        MoneyDialog
    },

    data() {
        return {
            send_to_company: 0,
            euro: Money.euro,
            loading_scrin: false,
            loading_tradicional: false
        }
    },

    methods: {
        perc(perc) {
            const parts = String(perc).split('.');
            while(String(parts[1]).length < 2) {
                parts[1] += "0"
            }
            return parts.join(',') + "%";
        },
        handleEditScrin() {
            const value = this.lead.infrainsurance && this.lead.infrainsurance.scrin_total ? this.lead.infrainsurance.scrin_total : 0;

            this.$refs.scrin_dialog.init(value);
        },
        handleEditTradicional() {
            const value = this.lead.infrainsurance && this.lead.infrainsurance.tradicional_total ? this.lead.infrainsurance.tradicional_total : 0;

            this.$refs.tradicional_dialog.init(value);
        },
        handleCancelScrin() {
            this.$refs.scrin_dialog.close();
        },
        handleCancelTradicional() {
            this.$refs.tradicional_dialog.close();
        },
        async updateTradicional(value) {
            this.loading_tradicional = true;

            try {
                const { data } = await this.$http.put(`leads/${this.lead.identifier_code}/infrainsurance`, {
                    tradicional_total: value
                });

                this.setLeadInfrainsurance(data);
                this.$refs.tradicional_dialog.close();
            } catch(error) {
                this.$store.commit('error', error);
            } finally {
                this.loading_tradicional = false;
            }
        },
        async updateScrin(value) {
            this.loading_scrin = true;

            try {
                const { data } = await this.$http.put(`leads/${this.lead.identifier_code}/infrainsurance`, {
                    scrin_total: value
                });

                this.setLeadInfrainsurance(data);
                this.$refs.scrin_dialog.close();
            } catch(error) {
                this.$store.commit('error', error);
            } finally {
                this.loading_scrin = false;
            }
        },
        async updateSendToCompany(value) {
            this.send_to_company = value;
            const decision = value === 0 ? null : value;

            const { data } = await this.$http.put(`leads/${this.lead.identifier_code}/infrainsurance`, {
                send_to_company: decision
            });
            this.setLeadInfrainsurance(data);
            this.$store.commit('alert', 'Salvo!')
        },
        setLeadInfrainsurance(infrainsurance) {
            this.$store.commit('setLead', {
                ...this.lead,
                infrainsurance
            });
        }
    },

    watch: {
        lead: function(value) {
            this.send_to_company = value.infrainsurance?.send_to_company || 0;
        }
    },

    created() {
        this.send_to_company = this.lead?.infrainsurance?.send_to_company || 0;
    },

    computed: {
        lead() {
            return this.$store.getters.getLead;
        },

        infrainsurance_tradicional_total() {
            if(!this.lead) return '-';
            return this.lead.infrainsurance?.tradicional_total ? this.euro(this.lead.infrainsurance?.tradicional_total) : '-'
        },

        infrainsurance_scrin_total() {
            if(!this.lead) return '-';
            return this.lead.infrainsurance?.scrin_total ? this.euro(this.lead.infrainsurance?.scrin_total) : '-'
        },

        infrainsurance_scrin_insured_capital() {
            if(!this.lead) return '-';
            return this.lead.infrainsurance?.scrin_insured_capital ? this.perc(this.lead.infrainsurance?.scrin_insured_capital) : '-'
        },

        infrainsurance_tradicional_insured_capital() {
            if(!this.lead) return '-';
            return this.lead.infrainsurance?.tradicional_insured_capital ? this.perc(this.lead.infrainsurance?.tradicional_insured_capital) : '-'
        },

        insured_object() {
            if(!this.lead) return null;

            return this.lead.policy.insurance_objects.find(insured_object => {
                return insured_object.type_code === "building";
            });
        },

        insured_capital() {
            return this.insured_object ? this.euro(this.insured_object.value) : 'Não definido';
        }
    }
}
</script>