<template>
    <v-menu>
        <template v-slot:activator="{on, attrs}">
            <v-btn v-on="on" v-bind="attrs" icon>
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list dense :disabled="loading">
            <v-list-item :disabled="insuranceObject.type_code === 'building'" @click="setBuildingInsuranceObject">
                <v-list-item-title>
                    {{ $capitalize($tc('model.infrainsurance_define_as_definition')) }} <span :class="{'green--text': insuranceObject.type_code !== 'building'}">{{ $capitalize($tc('model.infrainsurance_building_definition')) }}</span>
                </v-list-item-title>
            </v-list-item>
            <v-list-item :disabled="insuranceObject.type_code === 'contents'" @click="setContentsInsuranceObject">
                <v-list-item-title>
                    {{ $capitalize($tc('model.infrainsurance_define_as_definition')) }} <span :class="{'info--text': insuranceObject.type_code !== 'contents'}">{{ $capitalize($tc('model.infrainsurance_content_definition')) }}</span>
                </v-list-item-title>
            </v-list-item>
            <div v-if="insuranceObject.type_code !== null">
                <v-divider></v-divider>
                <v-list-item @click="removeDefinition">
                    <v-list-item-title>
                        <span class="red--text">{{ $capitalize($tc('model.infrainsurance_remove_definition')) }}</span>
                    </v-list-item-title>
                </v-list-item>
            </div>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    props: {
        insuranceObject: Object
    },

    data() {
        return {
            loading: false
        }
    },

    methods: {
        async setBuildingInsuranceObject() {
            const policy = this.lead.policy.id;

            this.loading = true;
            const { data } = await this.$http.post(`policies/${policy}/building_insurance_object`, {
                insurance_object_id: this.insuranceObject.id
            });
            this.loading = false;

            this.syncLead(data);
        },
        async setContentsInsuranceObject() {
            const policy = this.lead.policy.id;
            
            this.loading = true;
            const { data } = await this.$http.post(`policies/${policy}/contents_insurance_object`, {
                insurance_object_id: this.insuranceObject.id
            });
            this.loading = false;

            this.syncLead(data);
        },
        async removeDefinition()
        {
            const policy = this.lead.policy.id;
            
            this.loading = true;
            const { data } = await this.$http.post(`policies/${policy}/remove_insurance_object_definition`, {
                insurance_object_id: this.insuranceObject.id
            });
            this.loading = false;

            this.syncLead(data);
        },
        syncLead(policy) {
            this.$store.commit('setLead', {
                ...this.lead,
                policy: {
                    ...this.lead.policy,
                    insurance_objects: policy.insurance_objects,
                    building_insurance_object_id: policy.building_insurance_object_id,
                    contents_insurance_object_id: policy.contents_insurance_object_id
                }
            });
        }
    },
    computed: {
        lead() {
            return this.$store.getters.getLead;
        }
    }
}
</script>