<template>
    <v-dialog v-model="dialog" persistent :max-width="maxWidth">
        <ValidationObserver ref="form">
            <v-form @submit.prevent="submit">
                <v-card :disabled="loading">
                    <v-card-title>
                        {{ title }}
                        <v-spacer></v-spacer>
                        <v-btn @click="cancel" icon>
                            <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        <ValidationProvider
                        :rules="rules"
                        v-slot="{ errors }"
                    >
                            <MoneyInput
                                v-model="value"
                                autofocus
                                clearable
                                :error-messages="errors"
                                @keydown.enter="submit"
                            ></MoneyInput>
                        </ValidationProvider>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="cancel" text>
                            {{ cancelText }}
                        </v-btn>
                        <v-btn type="submit" color="primary">
                            {{ submitText }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
import MoneyInput from "@/components/MoneyInput";

export default {
    components: { MoneyInput },

    props: {
        title: String,
        submitText: {
            type: String,
            default: function() { return this.$capitalize(this.$tc('model.infrainsurance_save_button')); }
        },
        cancelText: {
            type: String,
            default: function() { return this.$capitalize(this.$tc('model.infrainsurance_cancel_button')); }
        },
        rules: [String, Object],
        loading: {
            type: Boolean,
            default: false
        },
        maxWidth: {
            type: Number,
            default: 600
        }
    },

    data() {
        return {
            dialog: false,
            value: 0
        }
    },

    methods: {
        init(value = 0) {
            this.dialog = true;
            this.value = value;
        },
        close() {
            this.dialog = false;
        },
        cancel() {
            this.$emit("cancel", this.value);
        },
        async submit() {
            const validation = await this.$refs.form.validate();
            if(!validation) return;

            this.$emit("submit", this.value);
        }
    }
}
</script>