<template>
  <div>
    <v-row>
      <v-col v-if="lead.participant_insured" cols="12">
        <v-card outlined>
          <v-card-title> {{ __("Segurado", "leads") }} </v-card-title>
          <v-list>
            <v-list-item @click="handleOpenInsured()">
              <v-list-item-icon>
                <v-icon color="primary">mdi-shield-account-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ lead.participant_insured.contact.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  NIF:
                  {{
                    lead.participant_insured.contact.fiscal_number || "---"
                  }}</v-list-item-subtitle
                >
                <v-list-item-subtitle>
                  {{ __("Contatos", "leads") }}:
                  {{ lead.participant_insured.contact.contact1 }}
                  {{ lead.participant_insured.contact.contact2 }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-title class="secondary--text">{{
            __("Apólice", "policy")
          }}</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-id-card</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    lead.policy.number
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    __("Número da apólice", "policy")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-calendar</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    toLocaleString(lead.policy.begin_date)
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    __("Data de início", "policy")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon> </v-list-item-icon>
                <v-list-item-content v-if="lead.policy.end_date">
                  <v-list-item-title>{{
                    toLocaleString(lead.policy.end_date) || "--"
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    __("Data final", "policy")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else>
                  Indeterminada
                  <v-list-item-subtitle>{{
                    __("Data final", "policy")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-title class="secondary--text">
            {{ __("Verbas seguras", "policy") }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ __("Descrição", "policy") }}
                  </th>
                  <th class="text-right">
                    {{ __("Valor", "policy") }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="insurance_object in lead.policy.insurance_objects"
                  :key="insurance_object.id"
                >
                  <td>
                    <span class="mr-1">
                      {{ insurance_object.type }}
                    </span>
                    <v-chip
                      v-if="insurance_object.type_code === 'building'"
                      x-small
                      label
                      color="green"
                      dark
                      outlined
                      right
                    >
                      EDIFÍCIO
                    </v-chip>
                    <v-chip
                      v-else-if="insurance_object.type_code === 'contents'"
                      x-small
                      label
                      color="info"
                      dark
                      outlined
                      right
                    >
                      CONTEÚDO
                    </v-chip>
                  </td>
                  <td class="text-right">
                    {{ euro(insurance_object.value) }}
                  </td>
                  <td width="50">
                    <insurance-object-changer
                      :insurance-object="insurance_object"
                    ></insurance-object-changer>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>

      <v-col v-if="isMultirisks" cols="12">
        <InfrainsuranceEditCard />
      </v-col>

      <v-col cols="12">
        <v-card class="mt-3" outlined>
          <v-row>
            <v-col cols="12" md="7">
              <v-card-title>
                {{ __("Coberturas", "policy") }}
                <v-spacer></v-spacer>
              </v-card-title>
            </v-col>
            <v-col class="pa-5 pt-8">
              <v-text-field
                v-model="search"
                :label="__('Buscar coberturas', 'policy')"
                prepend-inner-icon="mdi-magnify"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card-text class="my-0 py-0">
            <v-row>
              <v-col>
                <v-switch
                  v-model="showNotSelected"
                  :label="__('Exibir os não selecionados', 'policy')"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-card-text>

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="10"
            class="elevation-1"
            :loading="loading"
            :loading-text="__('Carregando...', 'policy')"
            fixed-header
            max-height="500"
            show-select
            single-select
            :value="selected_item()"
            item-key="id"
            disabled
            dense
            :sort-by="['selected']"
            :sort-desc="['selected']"
            :search="search"
          >
            <template v-slot:header.product_description>
              {{ __("Produtos", "policy", 1) }}
            </template>

            <template v-slot:header.type_description>
              {{ __("Descrição", "policy", 1) }}
            </template>

            <template v-slot:header.capital>
              {{ __("Capital", "policy", 1) }}
            </template>

            <template v-slot:header.franchise_value>
              {{ __("Valor", "policy", 1) }}
            </template>

            <template v-slot:header.franchise>
              {{ __("Franquia", "policy", 1) }}
            </template>

            <template v-slot:header.franchise_min>
              {{ __("Franquia mínima", "policy", 1) }}
            </template>

            <template v-slot:header.franchise_max>
              {{ __("Franquia máxima", "policy") }}
            </template>

            <template v-slot:header.data-table-select>
              <v-icon>mdi-check</v-icon>
            </template>

            <template v-slot:item.data-table-select="{ item }">
              <v-simple-checkbox
                v-if="item.selected === false"
                @click="handleSelectPolicyCoverage(item)"
              ></v-simple-checkbox>
              <v-simple-checkbox
                v-else
                @click="handleUnselectPolicyCoverage(item)"
                :value="true"
                color="primary"
                :disabled="item.selected && item.selected_by_id === null"
                :title="
                  item.selected_by_id
                    ? `Ativo internamente por ${item.selected_by.name}`
                    : 'Ativo pela companhia'
                "
              ></v-simple-checkbox>
            </template>

            <template v-slot:item.capital="{ item }">
              {{ euro(item.capital) }}
              <v-btn
                v-if="
                  $store.getters['auth/getScope'](
                    'leads.policy.coverages.capital.edit'
                  )
                "
                @click="handleClickEditCapital(item)"
                icon
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.franchise_value="{ item }">{{
              euro(item.franchise_value)
            }}</template>

            <template v-slot:item.franchise="{ item }"
              >{{ item.franchise }}%</template
            >

            <template v-slot:item.franchise_max="{ item }">
              {{ euro(item.franchise_max) }}
            </template>

            <template v-slot:item.franchise_min="{ item }">
              {{ euro(item.franchise_min) }}
            </template>

            <template v-slot:item.type_description="{ item }">
              {{ item.type_description }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="insured_dialog" max-width="600px" eager>
      <contact-form
        @cancel="handleCancelDialog"
        @submit="handleSubmitDialog"
        ref="insured_form"
        :id="insured_id"
      ></contact-form>
    </v-dialog>

    <PolicyCoverageCapitalForm
      ref="policyCoverageCapitalForm"
      @submit="updateCapital"
      :loading="loading_capital"
    ></PolicyCoverageCapitalForm>
  </div>
</template>

<script>
import Money from "../../tools/money";
import { formatDateTime } from "../../tools/date";
import goTo from "vuetify/es5/services/goto";
import ContactForm from "@/components/forms/ContactForm";
import PolicyCoverageCapitalForm from "@/components/forms/PolicyCoverageCapitalForm";
import InsuranceObjectChanger from "@/components/Lead/InsuranceObjectChanger";
import {
  selectPolicyCoverage,
  unselectPolicyCoverage,
  updateCapital,
} from "@/services/policy_coverage";
import InfrainsuranceEditCard from "@/components/Lead/Budget/InfrainsuranceEditCard";
import { lead_types } from "@/services/lead_types";
import i18n from "@/mixins/i18n";

export default {
  mixins: [i18n],
  components: {
    ContactForm,
    PolicyCoverageCapitalForm,
    InsuranceObjectChanger,
    InfrainsuranceEditCard,
  },
  data: () => ({
    headers: [
      {
        text: "Cód",
        value: "product_type",
      },
      {
        text: "Produto",
        value: "product_description",
        width: 200,
      },
      {
        text: "Descrição",
        value: "type_description",
        width: 250,
      },
      {
        text: "Capital",
        value: "capital",
        align: "right",
        width: 150,
      },
      {
        text: "Valor",
        value: "franchise_value",
        align: "right",
        width: 150,
      },
      {
        text: "Franquia",
        value: "franchise",
        align: "center",
      },
      {
        text: "Franquia Mínima",
        value: "franchise_min",
        align: "center",
      },
      {
        text: "Franquia Máxima",
        value: "franchise_max",
        align: "center",
      },
    ],
    totalItems: [],
    loading: false,
    search: "",
    showNotSelected: false,
    insured_dialog: false,
    insured_id: null,
    loading_capital: false,
    insured_id: null,
  }),
  methods: {
    handleClickEditCapital(item) {
      this.$refs.policyCoverageCapitalForm.init(item);
    },
    handleOpenInsured() {
      this.insured_id = this.lead.participant_insured.contact.id;
      // this.$refs['insured_form'].getContact(this.lead.participant_insured.contact.id);
      this.insured_dialog = true;
    },
    updatePolicyCoverage(id, attributes) {
      this.items = this.lead.policy.coverages.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            ...attributes,
          };
        }
        return item;
      });
    },
    async handleSelectPolicyCoverage(coverage) {
      if (
        !this.$store.getters["auth/getScope"]("leads.policy.coverages.select")
      ) {
        this.$store.commit(
          "alert",
          this.$capitalize(
            this.$tc("model.products_no_permission_activates_coverages_alert")
          )
        );
        return;
      }

      try {
        this.updatePolicyCoverage(coverage.id, {
          selected: true,
        });
        const newCoverage = await selectPolicyCoverage(coverage.id);
        this.updatePolicyCoverage(coverage.id, {
          selected_by_id: newCoverage.selected_by_id,
          selected_by: newCoverage.selected_by,
        });
        this.$store.commit(
          "alert",
          this.$capitalize(this.$tc("model.products_save_alert"))
        );
      } catch (error) {
        this.updatePolicyCoverage(coverage.id, {
          selected: false,
        });

        if (error.response) {
          if (error.response.status === 403) {
            this.$store.commit(
              "error",
              this.$capitalize(
                this.$tc(
                  "model.products_no_permission_activates_coverages_alert"
                )
              )
            );
            return;
          }

          this.$store.commit("error", error.response.data.message);
          return;
        }

        this.$store.commit(
          "error",
          this.$capitalize(
            this.$tc("model.products_unable_select_coverage_error")
          )
        );
      }
    },
    async handleUnselectPolicyCoverage(coverage) {
      if (coverage.selected_by_id === null) {
        return;
      }

      if (
        !this.$store.getters["auth/getScope"]("leads.policy.coverages.select")
      ) {
        this.$store.commit(
          "alert",
          this.$capitalize(
            this.$tc("model.products_no_permission_disable_coverages_alert")
          )
        );
        return;
      }

      try {
        this.updatePolicyCoverage(coverage.id, {
          selected: false,
        });
        const newCoverage = await unselectPolicyCoverage(coverage.id);
        this.updatePolicyCoverage(coverage.id, {
          selected_by_id: newCoverage.selected_by_id,
          selected_by: newCoverage.selected_by,
        });
        this.$store.commit(
          "alert",
          this.$capitalize(this.$tc("model.products_save_alert"))
        );
      } catch (error) {
        this.updatePolicyCoverage(coverage.id, {
          selected: true,
        });

        if (error.response) {
          if (error.response.status === 403) {
            this.$store.commit(
              "error",
              this.$capitalize(
                this.$tc("model.products_no_permission_disable_coverages_error")
              )
            );
            return;
          }

          this.$store.commit("error", error.response.data.message);
          return;
        }

        this.$store.commit(
          "error",
          this.$capitalize(
            this.$tc("model.products_could_not_turn_off_coverage_error")
          )
        );
      }
    },
    async updateCapital(payload) {
      const { capital, item } = payload;

      this.loading_capital = true;
      try {
        const response = await updateCapital(capital, item.id);

        this.updatePolicyCoverage(item.id, response);

        this.$refs.policyCoverageCapitalForm.close();
        this.$store.commit(
          "alert",
          this.$capitalize(this.$tc("model.products_save_alert"))
        );
      } catch (error) {
        this.$store.commit(
          "error",
          this.$capitalize(
            this.$tc("model.products_unable_change_capital_error")
          )
        );
      } finally {
        this.loading_capital = false;
      }
    },
    handleCancelDialog() {
      this.insured_id = null;
      this.insured_dialog = false;
    },
    handleSubmitDialog() {
      this.insured_id = null;
      this.insured_dialog = false;
    },
    selected_item() {
      return [this.items.find((item) => item.selected)];
    },
    customSort(items) {
      return items.selected;
    },
    euro(value) {
      return Money.euro(value);
    },
    toLocaleString(value) {
      return formatDateTime(value);
    },
  },
  created() {
    goTo(0);
  },
  computed: {
    lead: function () {
      return this.$store.getters.getLead;
    },
    isMultirisks() {
      return this.lead?.lead_type === lead_types.multirisks;
    },
    items: {
      get() {
        if (this.lead) {
          const coverages = this.lead.policy.coverages.filter((coverage) => {
            //Retorna todas as leads caso marcado
            if (this.showNotSelected) {
              return true;
            } else {
              // Caso contrário, apenas as selecionadas
              return coverage.selected;
            }
          });

          return coverages.sort((a, b) => {
            if (!!a.selected_by_id && !!!b.selected_by_id) {
              return 1;
            }
            if (!!!a.selected_by_id && !!b.selected_by_id) {
              return -1;
            }

            return 0;
          });
        } else {
          return [];
        }
      },
      set(value) {
        this.$store.commit("setLead", {
          ...this.lead,
          policy: {
            ...this.lead.policy,
            coverages: value,
          },
        });
      },
    },
  },
};
</script>
