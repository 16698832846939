import Vue from "vue";

export async function indexUsedCoverages(lead_number) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/used_coverages`
  );

  return response.data;
}

export async function updateFranchise(id, payload) {
  const response = await Vue.prototype.$http.put(
    `/policy_coverage/${id}/franchise`,
    payload
  );

  return response.data;
}

export async function selectPolicyCoverage(id) {
  const response = await Vue.prototype.$http.post(
    `/policy_coverage/${id}/select`,
  );

  return response.data;
}

export async function unselectPolicyCoverage(id) {
  const response = await Vue.prototype.$http.post(
    `/policy_coverage/${id}/unselect`,
  );

  return response.data;
}

export function calcTotalToSpend(coverage) {
  return (
    Number(coverage.capital || 0) + Number(coverage.franchise_internal || 0)
  );
}

export async function updateCapital(capital, id) {
  const response = await Vue.prototype.$http.post(
    `/policy_coverage/${id}/capital`,
    { capital }
  );

  return response.data;
}